<template>
    <div class="through-page chart-page--container flex-item">
        <div class="common-table__title">
            按药品穿透
        </div>

        <div class="through-page-panel" v-loading="loading">
            <ts-error v-if="message"></ts-error>
            <template v-else>
                <div class="through-page-assist">
                    <el-popover placement="right-start" width="280" trigger="hover">
                        <el-button slot="reference" type="primary" size="small">指引</el-button>
                        <p class="through-page-tips">
                            1、<span class="red">红色框</span>中的数字表示该剂型的药品数量；<br>
                            2、点击<span class="red">红色框</span>可以查看该药品剂型、近年销售金额及排名；<br>
                            3、<span class="blue">蓝色框</span>中的数字表示该药品的生产企业数量；<br>
                            4、点击<span class="blue">蓝色框</span>可以查看该药品生产企业<br>
                        </p>
                    </el-popover>
                    <div v-show="showSelect" class="through-page-assist__center">
                        <el-select class="flex-item" size="small" v-model="model" filterable remote reserve-keyword @change="init"
                                   placeholder="请输入关键词搜索" :remote-method="remoteMethod" :loading="dataLoading" value-key="id">
                            <el-option v-for="item in option" :key="item.id" :label="item.keyName"  :value="item"></el-option>
                        </el-select>
                    </div>
                    <div class="through-page-assist__bottom">
                        <i class="clickable el-icon-caret-bottom" :class="{active: showSelect}" @click="showSelect = !showSelect"></i>
                    </div>
                </div>

                <div :id="key" v-if="loaded" class="through-page-canvas"></div>

                <through-table ref="through" :text="key" :col="cols" ></through-table>
            </template>
        </div>
    </div>
</template>

<script>
import throughMixin from './throughMixin'
import ThroughTable from './ThroughTable'

export default {
    mixins: [throughMixin],
    components: {ThroughTable},
    data() {
        return {
            visitId: new Date().getTime(),
            pageId: 'throughDose',
            pageName: '按药品穿透',

            key: 'throughDose',

            dataLoading: false,
            model: {},
            option: [],

            loaded: true,

            message: '',

            config: {
                drugKey: {
                    type: 'drugKey',
                    nextType: 'corp',
                    query: 'dr/aimBaseDrugs/queryAimBaseDrugsPage',
                    queryField: 'keyName',   // 查询参数字段
                    keywordField: 'comName', // 搜索字段
                    showField: 'comName',  // 显示字段
                    saleAmount:'saleAmount',
                    countField: 'compNameCount',  // 计数字段
                    color: '#576EEB',
                    countShow: '',  // 计数字段显示
                },
                corp: {
                    type: 'corp',
                    nextType: 'drug',
                    query: 'dr/aimBaseDrugs/queryDomesticDrugsForCompInfoPage',
                    queryField: 'comCode',
                    keywordField: 'compName',
                    showField: 'compName',
                    saleAmount:'lastYearCompAmount',
                    countField: 'comNumber',
                    countShow: '药品数量',
                    color: '#9F18F9',
                },
                drug: {
                    type: 'drug',
                    nextType: 'corp',
                    query: 'dr/aimBaseDrugs/queryDomesticDrugsForComInfoPage',
                    queryField: 'compCode',
                    keywordField: 'comName',
                    showField: 'comName',
                    hideCount:true,
                    saleAmount:'lastYearComAmount',
                    countField: 'manufacturersNumber',
                    countShow: '企业数量',
                    color: '#576EEB',
                },
            },
            cols:[
                {key:'basicMedicine',value:'基药'},
                {key:'healthInsurance',value:'医保'},
            ]
        }
    },
    created() {
        this.$help.socket.send(this)
        this.remoteMethod('感冒')
    },
    methods: {
        remoteMethod(keyword) {
            this.dataLoading = true
            this.$api.get('setting/baDrugsKeys/queryBaDrugsKeysListByKeyword', {keyword}, {'Visit-Id': this.$help.getVisitId(this.visitId)})
                .then(res => {
                    if (res.success) {
                        this.option = res.data

                        if (!this.model.id && this.option.length > 0) {
                            this.model = this.option[0]
                            this.init()
                        }
                    } else {
                        this.message = res.message
                    }
                })
                .finally(() => {
                    this.dataLoading = false
                })
        },
        init() {
            this.loading = true
            this.loaded = false
            this.$nextTick(() => {
                this.loaded = true
            })
            this.antv = null
            this.$api.post(this.config.drugKey.query, {
                page: {
                    pageNo: 1,
                    pageSize: this.pageSize,
                },
                params: {
                    [this.config.drugKey.queryField]: this.model.keyName,
                }
            })
                .then(res => {
                    if (res.data.records && res.data.records.length > 0) {
                        const obj = {
                            id: this.model.id,
                            loaded: true,
                            label: `${this.model.keyName}(${res.data.totalCount})`,
                            data: this.model,
                            style: {
                                fill: '#576EEB',
                            },
                            children: this.getNodeList(
                                res.data.records,
                                this.config.drugKey,
                                {
                                    parentTitle: this.model.keyName,
                                    parentModel: {data: this.model},
                                }
                            ),
                        }

                        this.antv = this.renderG6(obj)

                        this.antv.on('node:click', evt => {
                            const item = evt.item
                            const model = item.getModel()
                            if (model.isFull) {
                                this.$refs.through.init(model)
                            } else if (!model.loaded) {
                                model.loaded = true
                                this.loading = true
                                const config = this.config[model.nextType]
                                this.$api.post(config.query, {
                                    page: {
                                        pageNo: 1,
                                        pageSize: this.pageSize,
                                    },
                                    params: {
                                        [config.queryField]: model.data[config.queryField],
                                    }
                                })
                                    .then(res => {
                                        setTimeout(() => {
                                            model.children = this.getNodeList(
                                                res.data.records,
                                                config,
                                                {
                                                    parentTitle: model.clearLabel,
                                                    parentModel: model,
                                                }
                                            )
                                            this.antv.changeData()
                                        }, 300)
                                    })
                                    .finally(() => {
                                        this.loading = false
                                    })
                            }
                        })
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
    }
}
</script>